import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Books from "../components/books"
import { StaticImage } from "gatsby-plugin-image"
import * as aboutStyles from "./about.module.css"

const about = () => {
  return (
    <Layout>
      <Seo title="About" />
      <section className="container">
        <h2 className="title">about me.</h2>
        <StaticImage
          src="../images/Jente.png"
          alt="Jente Stevens"
          className={aboutStyles.image}
          placeholder="none"
          loading="eager"
        />
        <p className={`description ${aboutStyles.text}`}>
          <br />
          <span>
            I would describe myself as someone who is entrepreneurial, eager to
            learn and willing to go the extra mile.
          </span>
          <br />
          <br />
          <span>
            My personality type is INTP and that says it all: an abstract
            thinker who likes brainstorming on theoritical concepts.
          </span>
          <br />
          <br />
          <span>
            However, during the past few years I acquired the right skills to
            put these ideas into practice.
          </span>
          <br />
          <br />
          <span>
            I always like to approach problems strategically and think about
            creative solutions in a later phase.
          </span>
        </p>
        <div className={aboutStyles.booksWrapper}>
          <Books />
        </div>
      </section>
    </Layout>
  )
}

export default about
